<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12">
      <!-- FILTROS -->
      <v-expansion-panels v-model="panel">
        <v-expansion-panel readonly>
          <v-expansion-panel-header class="py-0 px-4 text-h5" style="cursor: default">
            <div>
              <v-icon class="mb-1" left>fas fa-filter</v-icon>
              Filtro
            </div>
            <template v-slot:actions>
              <v-btn icon @click.stop="panel = panel === 1 ? 0 : 1">
                <v-icon>fas fa-chevron-down</v-icon>
              </v-btn>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pt-1">
            <v-form @submit.prevent="buscar()">
              <v-row>
                <v-col cols="6" sm="2" xl="1" class="py-1">
                  Empresa
                  <v-autocomplete
                    v-model="filtro.empresa"
                    item-text="nombre_corto"
                    item-value="id"
                    tabindex="1"
                    :items="empresas"
                    hide-details
                    outlined
                    readonly
                    filled
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" xl="2" class="py-1">
                  Sucursal
                  <v-autocomplete
                    v-model="filtro.sucursal"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="sucursales"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="filtro.local = null; filtro.emision = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" class="py-1">
                  Local
                  <v-autocomplete
                    v-model="filtro.local"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="locales.filter(lo => lo.sucursal == filtro.sucursal)"
                    hide-details
                    clearable
                    outlined
                    dense
                    @change="filtro.emision = null"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" xl="2" class="py-1">
                  Emisión
                  <v-autocomplete
                    v-model="filtro.emision"
                    item-text="codigo"
                    item-value="codigo"
                    tabindex="1"
                    :items="emisiones.filter(em => em.local == filtro.local)"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" xl="3" class="py-1">
                  Tipo pedido
                  <v-autocomplete
                    v-model="filtro.tipo_pedido"
                    item-text="nombre"
                    item-value="id"
                    tabindex="1"
                    :items="tipos_pedido"
                    hide-details
                    clearable
                    outlined
                    dense
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="4" xl="3" class="py-1">
                  <v-row>
                    <v-col>
                      Fecha desde
                      <FechaPicker
                        v-model="filtro.desde"
                        hide-details
                      />
                    </v-col>
                    <v-col>
                      Fecha hasta
                      <FechaPicker
                        v-model="filtro.hasta"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="3" xl="2" class="py-1">
                  <v-row>
                    <v-col>
                      Comp. desde
                      <v-text-field
                        v-model.trim="filtro.comp_desde"
                        type="number"
                        tabindex="1"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      Comp. hasta
                      <v-text-field
                        v-model.trim="filtro.comp_hasta"
                        type="number"
                        tabindex="1"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <BtnFiltro
                  nombre="Generar archivos"
                  icono="fas fa-download"
                  @clear="limpiar()"
                />
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import BtnFiltro from '../../components/util/BtnFiltro'
import FechaPicker from '../../components/util/FechaPicker'
import download from 'downloadjs'

export default {
  data () {
    return {
      panel: 0,
      filtro: {
        empresa: 2,
        sucursal: null,
        local: null,
        tipo_pedido: null,
        emision: null,
        desde: null,
        hasta: null,
        comp_desde: null,
        comp_hasta: null
      }
    }
  },
  async created () {
    this.$store.state.loading = true
    await this.$store.dispatch('genericosAcc/get_sucursales')
    await this.$store.dispatch('bejerman/get_tipos_pedido')
    await this.$store.dispatch('bejerman/get_emisiones')
    await this.$store.dispatch('bejerman/get_locales')
    this.$store.state.loading = false
  },
  computed: {
    ...mapState(['empresas']),
    ...mapState('genericosAcc', ['sucursales']),
    ...mapState('bejerman', ['tipos_pedido', 'emisiones', 'locales'])
  },
  components: {
    BtnFiltro,
    FechaPicker
  },
  methods: {
    async buscar () {
      if (!this.filtro.tipo_pedido) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar un tipo de pedido',
          color: 'orange'
        })
      }
      if (!this.filtro.desde || !this.filtro.desde) {
        return this.$store.dispatch('show_snackbar', {
          text: 'Debe seleccionar periodo de fechas',
          color: 'orange'
        })
      }
      this.$store.state.loading = true
      await this.$store.dispatch('bejerman/generar_archivos', this.filtro)
        .then((res) => {
          for (const archivo of res.data) {
            const cadena = archivo.datos.join('\r\n')
            download(new Blob([`${cadena}${cadena ? '\r\n' : ''}`], {type: "text/plain;charset=utf-8"}), `${archivo.nombre}.txt`, 'text/plain')
          }
        })
        .catch(error => {
          this.$store.dispatch('show_snackbar', {
            text: error.message,
            color: 'error'
          })
        })
      this.$store.state.loading = false
    },
    limpiar () {
      this.filtro = {
        empresa: 2,
        sucursal: null,
        local: null,
        tipo_pedido: null,
        emision: null,
        desde: null,
        hasta: null,
        comp_desde: null,
        comp_hasta: null
      }
    }
  }
}
</script>